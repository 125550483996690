var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('transition',{attrs:{"appear":"","name":"slide-fade"}},[_c('div',{staticClass:"table-ctn"},[_c('div',{staticClass:"tbl-wrapper"},[_c('el-table',{attrs:{"data":_vm.tableData,"row-class-name":"clickable"},on:{"row-click":_vm.openDetail}},[_c('el-table-column',{attrs:{"label":_vm.$t('dashboard.tasklist.task_id'),"prop":"id"}}),_c('el-table-column',{attrs:{"label":_vm.$t('dashboard.tasklist.task_name'),"prop":"name"}}),_c('el-table-column',{attrs:{"label":_vm.$t('dashboard.tasklist.task_type'),"prop":"type"}}),_c('el-table-column',{attrs:{"label":_vm.$t('dashboard.tasklist.creator_node'),"prop":"creator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.creator)+"("+_vm._s(_vm.nodes[row.creator])+") ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('dashboard.tasklist.creator_account'),"prop":"creator_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.creator_name || "--")+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('dashboard.tasklist.status'),"prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status == _vm.$appGlobal.constants.TASK_STATUS_PENDING)?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.tasklist.pending'))+" ")]):(row.status == _vm.$appGlobal.constants.TASK_STATUS_RUNNING)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.tasklist.running'))+" ")]):(row.status == _vm.$appGlobal.constants.TASK_STATUS_ERROR)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.tasklist.error'))+" ")]):(row.status == _vm.$appGlobal.constants.TASK_STATUS_FINISHED)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.tasklist.finished'))+" ")]):(row.status == _vm.$appGlobal.constants.TASK_STATUS_CONFIRMED)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.tasklist.confirmed'))+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"created_at","label":_vm.$t('common.created_at')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("second2Date")((row.created_at / 1000))))]}}])},[_vm._v(_vm._s()+" ")])],1)],1),_c('div',{staticClass:"paging"},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.currentPage,"total":_vm.totalCount,"layout":"prev, pager, next","page-size":_vm.taskPageSize,"hide-on-single-page":true},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"current-change":_vm.load}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }